@import 'bootstrap';

@import 'session';


// ================================
// global styles

body {
    overflow: auto;
    font-family: "Source Sans Pro", sans-serif;
}

h2 {
    font-size: 100%;
    text-align: center;
    margin: 0px;
    padding-bottom: 20px;
    font-weight: bold;
    color: #999;
    text-transform: uppercase;
}



// ================================
// navbar styles

header {
    margin-bottom: 30px;
    position: relative;

    .logo-xs {
        margin-top: 30px;
    }

    .navbar {
        border: 0;
    }

    .navbar-brand {
        position: absolute;
        z-index: 1000;

        img {
            float: left;
            vertical-align: bottom;

            &.title {
                padding-top: 30px;
            }
        }
    }

    .navbar-inverse {
        border-bottom: 1px solid #e0e0e0;
        background: none;
    }

    .navbar-default {
        border-bottom: 3px solid @brand-primary;
        min-height: 70px;
    }

    .navbar-header {
        padding: 10px;
    }
}

.well {
    background-color: #ffffff;
}

.sso-login {
    padding: 3rem 5rem;

    .title {
        text-align: center;
        color: #2C2C2C;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 1;
    }

    .subtitle {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: #292C3D;
    }

    .dark-gray {
        color: #63635F;
    }

    .medium {
        color: #757575;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.6rem;
        margin-top: 2.5rem;
    }

    .sso-social-login-wrapper {
        display: flex;
        margin-top: -1rem;
    }
    .horizontal-line {
        position: relative;
        padding: 0 1.3rem;
    }

    .horizontal-line:after {
        content: '';
        height: 30%;
        width: 0.01rem;
        position: absolute;
        top: 50%;
        background-color: #757575;
        opacity: 0.3;
    }

    .sso-btn-social {
        background: none;
        box-shadow: none;
        padding: 0;
        margin-top: 1rem;
    }
    .form-control {
        border-radius: 0.4rem;
        height: 4.2rem;
    }
    .form-control:focus {
        border: 0.5px solid #1976D2;
        box-shadow: 0px 0px 14px -5px rgba(70, 164, 226, 0.32);
    }

    .form-group.has-error .form-control {
        border: 0.5px solid #C1282F;
    }
    .form-group label {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 1.5;
        color: #63635F;
    }
    .form-group.has-error .help-block {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1;
        color: #C1282F;
    }

    .terms-and-privacy {
        font-size: 1.4rem;
        line-height: 1.8;
        text-align: center;
        color: #757575;
        margin-top: 1rem;
    }

    .btn-submit {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 1;
        background: #B23736;
        border-radius: 4px;
        border: none;
        padding: 1.35rem 0;
    }

    .btn-submit:hover {
        background: #c43b3a;
    }

    input[type=checkbox] {
        transform: scale(1.45);
        margin: 0.2rem -2.5rem;
    }

    .agreement {
        margin-left: 1rem;
    }
    .form-group .toggle-password {
        position: absolute;
        right: 1.5rem;
        top: 4.5rem;
        color: #2854C5;
        font-size: 1.8rem;
        cursor: pointer;
    }
    a {
        color: #2854C5;
        text-decoration: underline;
    }

    .forgot-password {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 0.8;
    }

    .remember-me {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1;
        color: #000000;


        input[type="checkbox"] {
            position: relative;
            width: 15px;
            height: 15px;
            color: #000000;
            border: 2px solid #000000;
            border-radius: 4px;
            appearance: none;
            outline: 0;
            margin: 0;
            cursor: pointer;
            transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

             &::before {
                 position: absolute;
                 content: '';
                 display: block;
                 top: 1px;
                 left: 4px;
                 width: 4px;
                 height: 8px;
                 border-style: solid;
                 border-color: #000000;
                 border-width: 0 2px 2px 0;
                 transform: rotate(45deg);
                 opacity: 0;
             }

            &:checked {
                color: #000000;
                border-color: #000000;
                background: #ffffff;

                &::before {
                    opacity: 1;
                }

                & ~ label::before {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                }
            }
        }

        label {
            position: relative;
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            padding: 0 0.25em 0;
            user-select: none;
            color: #000000;

            &::before {
                position: absolute;
                content: attr(data-content);
                color: lightgrey;
                clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
                text-decoration: line-through;
                text-decoration-thickness: 3px;
                text-decoration-color: black;
                transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }
        }

    }
}

.sso-password-reset {
    .title {
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 2;
        color: #2C2C2C;
    }
    .subtitle {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 1.25;
        color: #63635F;
        margin: 2rem 0;
    }

    .subtitle.success {
        font-size: 2.2rem;
        margin-bottom: 0;
    }
    .subtitle {
        a {
            color: #2854C5;
            text-decoration: underline;
            white-space: nowrap;
        }
    }
    .back-login {
        font-style: normal;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 1.5;
        color: #222222;
        border: none;
        background: none;
        margin-bottom: 5rem;
        display: inline-block;
    }
    .form-group {
        label {
            font-weight: 400;
            font-size: 2.2rem;
            line-height: 1.5;
            color: #63635F;
            float: left;
        }
    }


    .form-control {
        border-radius: 0.4rem;
        height: 4.2rem;

        &:focus {
            border: 0.5px solid #1976D2;
            box-shadow: 0px 0px 14px -5px rgba(70, 164, 226, 0.32);
        }
    }

    .form-group.has-error {

        .help-block {
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 1;
            color: #C1282F;
        }

        .form-control {
            border: 0.5px solid #C1282F;
        }
    }

    .reset-password-form {
        align-items: center;
        text-align: center;
        margin: 4rem auto;

        .login {
            padding: 1.35rem 0;
            background: #B23736;
            border-radius: 0.4rem;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 0.8;
            color: #FFFFFF;
            display: inline-block;
            margin: 1.5rem 0;
            width: 55%;
        }

        button {
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 0.8;
            color: #FFFFFF;
            padding: 1.35rem 0;
            width: 100%;
            margin: 2rem 0;
        }
    }
}

.sso-success-registration {
    img {
        display: inline-block;
    }

    h3 {
        font-weight: 600;
        font-size: 2.8rem;
        line-height: 1.6;
        color: #2C2C2C;
    }

    p {
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 1.4;
        color: #63635F;
    }

    a {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 1.4;
        color: #2C2C2C;
        background: #ffffff;
        padding: 1rem 10rem;
        cursor: pointer;
        border-radius: 8px;
        text-decoration: none;
        margin-top: 2rem;
        display: inline-block;
        box-shadow: 0px 4px 20px rgba(194, 194, 194, 0.25);

        svg {
            margin-right: 1rem;
            vertical-align: bottom;
        }
    }
}

.toast {
    border-radius: 8px;
    background: #ffffff;
    max-width: 410px;
    box-shadow: 0px 4px 10px rgba(202, 202, 202, 0.25);
    position: absolute;
    z-index: 5;
    right: 30px;
    top: 30px;
    visibility: hidden;

    .toast-body {
        position: relative;
        display: flex;

        img {
            height: 32px;
            width: 32px;
            align-self: center;
            margin-right: 12px;
            margin-left: 5px;
        }
    }

    .message {
        margin-right: 5rem;

        h3 {
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.2;
            color: #292C3D;
            margin-top: 12px;
        }
        h4 {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 1;
            color: #63635F;
        }
    }

    button {
        position: absolute;
        top: 0.5rem;
        right: 1.2rem;
        font-size: 25px;
        font-weight: 200;
        color: #000000;
        opacity: 1;
        border: none;
        outline: none;
        background: none;
    }
}

#signup_success_toast {
    border-left: 5px solid #4AC860;
}

#signup_error_toast {
    border-left: 5px solid #FB5C5B;
}

.create-account {
    font-size: 1.8rem;
    line-height: 0.8;
    color: #63635F;
    margin-top: 2rem;
}

.mt-2 {
    margin-top: 2rem !important;
}


@media screen and (max-width: 480px) {
    .sso-login {
        padding: 0 2rem 2rem;

        .title {
            font-size: 2.4rem;
        }

        .subtitle {
            font-size: 1.4rem;
        }

        .medium {
            font-size: 1.2rem;
            margin-top: 2rem;
        }

        .form-control {
            height: 3.6rem;
        }
        .terms-and-privacy {
            font-size: 1.2rem;
            line-height: 1.4;
        }

        .btn-submit {
            font-size: 1.6rem;
            padding: 1.2rem 0;
        }

        input[type=checkbox] {
            transform: scale(1.45);
            margin: 0.2rem -2.5rem;
        }

        .agreement {
            margin-left: 1rem;
        }

        .form-group {
            label {
                font-size: 1.2rem;
                line-height: 1.2;
            }
            .toggle-password {
                top: 2.9rem;
            }
        }

        a {
            white-space: nowrap;
        }

        .forgot-password {
            font-size: 1.4rem;
            line-height: 1.2;
        }

        .remember-me {
            white-space: nowrap;

            input[type="checkbox"] {
                width: 13px;
                height: 13px;

                &::before {
                    top: 1px;
                    left: 3px;
                    width: 4px;
                    height: 7px;
                }
            }

            label {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    .sso-password-reset {
        .title {
            font-size: 2.4rem;
            line-height: 1.5;
            margin-top: 0;
        }

        .subtitle {
            font-size: 1.4rem;
            line-height: 1.15;
        }

        .subtitle.success {
            font-size: 2.2rem;
            margin-bottom: 0;
        }

        .back-login {
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }
        .form-control {
            height: 3.6rem;
        }

        .form-group {
            label {
                font-size: 1.4rem;
                line-height: 1.2;
            }
        }

        .reset-password-form {
            .login {
                font-size: 1.4rem;
            }

            button{
                font-size: 1.5rem;
                padding: 1.25rem 0;
            }
        }

        .form-group.has-error {
            .help-block {
                font-size: 1.2rem;
            }
        }
    }

    .sso-success-registration {
        h3 {
            font-size: 2.4rem;
            line-height: 1.4;
        }
        p {
            font-size: 1.4rem;
        }
    }

    .create-account {
        font-size: 1.4rem;
    }
    .mt-2 {
        margin-top: 2rem !important;
    }
}
